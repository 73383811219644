'use client';
import { cssMerge } from '@volvo-cars/css/utils';
import type React from 'react';
import { Disclaimer } from './Disclaimer';
import { useDisclaimers } from './DisclaimerContext';

type DisclaimerAppendixProps = {
  className?: string;
};

/**
 * DisclaimerAppendix component renders a list of disclaimers of type 'appendix'.
 *
 * This component uses the `useDisclaimers` hook to collect the disclaimers from
 * `DisclaimerContext` and filters them to include only those with the type 'appendix'.
 * It then maps over these disclaimers and renders each one inside a list item (`<li>`),
 * using the `Disclaimer` component.
 *
 * @returns {JSX.Element} An ordered list (`<ol>`) of disclaimers.
 */
export const DisclaimerAppendix: React.FC<DisclaimerAppendixProps> = ({
  className,
}) => {
  const { disclaimers } = useDisclaimers();
  const appendixDisclaimers = disclaimers.filter((d) => d.type === 'appendix');

  if (appendixDisclaimers.length === 0) return null;

  return (
    <ol className={cssMerge('micro', className)}>
      {appendixDisclaimers.map((disclaimer) => (
        <li className="flex justify-center text-center" key={disclaimer.id}>
          <Disclaimer
            includeSymbol
            id={disclaimer.id}
            text={disclaimer.text}
            sources={disclaimer.sources}
          />
        </li>
      ))}
    </ol>
  );
};
