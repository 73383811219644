'use client';

import type { LinkField } from '@vcc-www/content-management-jss-client';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { Icon } from '@volvo-cars/react-icons';
import { useKeyPress } from '@volvo-cars/react-utils';
import { Track } from '@volvo-cars/tracking';
import type React from 'react';
import { type CSSProperties, useState } from 'react';
import { DisclaimerDialog } from '../disclaimer-dialog/DisclaimerDialog';

export type PopUpDisclaimerContent = {
  title?: string;
  titleSource?: string;
  description: string;
  descriptionSource?: string;
  descriptionTitle?: string;
  descriptionTitleSource?: string;
  link?: LinkField;
  linkSources?: string[];
};

export type PopUpDisclaimerProps = PopUpDisclaimerContent & {
  iconVerticalAlign?: CSSProperties['verticalAlign'];
  iconStyle?: string;
  trackLabel?: string;
  addMarginInlineStart?: boolean;
};

export const PopUpDisclaimer: React.FC<
  PopUpDisclaimerProps & {
    children: (icon: React.ReactNode) => React.ReactNode;
  }
> = ({
  title,
  titleSource,
  description,
  descriptionSource,
  descriptionTitle,
  descriptionTitleSource,
  link,
  linkSources = ['', ''],
  iconVerticalAlign = 'baseline',
  iconStyle,
  trackLabel,
  addMarginInlineStart,
  children,
}) => {
  const [openOverlay, showDisclaimerOverlay] = useState(false);
  const sharedTranslate = useSharedComponentsTranslate();

  const ariaLabel = sharedTranslate(
    'ReactOverlay.labels.popUpDisclaimerAriaLabel',
    { title },
  );

  useKeyPress('Escape', () => showDisclaimerOverlay(false));
  const disclaimerIcon = (
    <Track
      eventLabel={trackLabel || `open PopUpDisclaimer | ${title}`}
      eventAction="image|click"
    >
      <button
        onClick={() => showDisclaimerOverlay(true)}
        type="button"
        className={`${addMarginInlineStart ? 'ml-4' : ''} ${
          iconStyle ? iconStyle : ''
        }`}
        aria-label={ariaLabel}
        style={{ verticalAlign: iconVerticalAlign }}
      >
        <Icon icon="info-circled" size={16} />
      </button>
    </Track>
  );

  return (
    <>
      {children(disclaimerIcon)}
      <DisclaimerDialog
        title={title}
        titleSource={titleSource}
        description={description}
        descriptionSource={descriptionSource}
        descriptionTitle={descriptionTitle}
        descriptionTitleSource={descriptionTitleSource}
        open={openOverlay}
        onClose={() => showDisclaimerOverlay(false)}
        link={link}
        linkSources={linkSources}
      />
    </>
  );
};
